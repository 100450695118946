<template>
  <div class="question-list">
    <el-breadcrumb separator="> " class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/questionTemplate' }"> 模板管理 </el-breadcrumb-item>
      <el-breadcrumb-item>查看模板</el-breadcrumb-item>
    </el-breadcrumb>
    <h2 class="page_title">知识库模板详情</h2>

    <!-- 模版设置 -->
    <div class="group_title">模板设置</div>
    <el-form label-width="170px" label-suffix=":" label-position="right">
      <el-form-item label="模板名称">
        {{ templateName }}
      </el-form-item>

      <!-- 内容设置 -->
      <div class="group_title mt20">内容设置</div>
      <div v-if="classificaReqDTOS.length" class="tabs">
        <div
          v-for="(item, index) in classificaReqDTOS"
          :key="index"
          :active="activeClassIndex === index"
          @click="activeClassIndex = index"
          class="tab_item"
        >
          <div class="tab_title">
            <span>{{ item.classificaName }}</span>
          </div>
        </div>
      </div>
      <div v-else class="emtye_data">暂无内容</div>
      <div v-if="classificaReqDTOS[activeClassIndex].problemList">
        <el-table
          ref="elTable"
          v-show="classificaReqDTOS[activeClassIndex].problemList.length"
          :data="classificaReqDTOS[activeClassIndex].problemList"
          style="width: 100%;"
        >
          <el-table-column prop="titleCode" label="问题编码" />
          <el-table-column prop="title" label="问题名称" />
          <el-table-column label="答复">
            <template slot-scope="scope">
              <div v-html="scope.row.reply" />
            </template>
          </el-table-column>
        </el-table>
      </div>

      <!-- 其他设置 -->
      <div class="group_title">其他设置</div>
      <el-form-item label="自助页banner">
        <el-link
          v-if="bannerUrl"
          :href="bannerUrl"
          :underline="false"
          type="primary"
          target="__blank"
        >
          查看文件
        </el-link>

        <template v-else> - </template>
      </el-form-item>
      <el-form-item label="其他问题内容"> {{ otherProblems }} </el-form-item>
      <div v-if="item.clickEffect" v-for="(item, idx) in btnConfigs" :key="idx" class="">
        <el-row>
          <el-col>
            <el-form-item label="按钮一名称">{{ item.buttonName }}</el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item label="按钮一点击效果">{{ item.clickEffect | clickEffect }}</el-form-item>
          </el-col>

          <el-col v-if="item.clickEffect === '1'" :span="16">
            <el-form-item label="弹窗文案">
              <el-tooltip :content="item.popUpText" effect="dark" placement="top">
                <el-button type="text" class="text">{{ item.popUpText }}</el-button>
              </el-tooltip>
            </el-form-item>
          </el-col>

          <el-col v-if="item.clickEffect === '2'" :span="16">
            <el-form-item label="链接">
              <el-tooltip :content="item.jumpLink" effect="dark" placement="top">
                <el-button type="text" class="text">{{ item.jumpLink }}</el-button>
              </el-tooltip>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
    </el-form>
  </div>
</template>

<script>
import { question } from '@/api';

export default {
  filters: {
    optionsMap(value) {
      return { 0: '否', 1: '是' }[value];
    },
    /** @name 按钮点击效果 */
    clickEffect(value) {
      return {
        0: '无效果',
        1: '弹窗',
        2: '跳转链接',
      }[value];
    },
  },
  data() {
    return {
      activeClassIndex: 0,
      templateName: '', // 模版名称
      telCustomerHotline: '', // 电话客服热线
      showSmartCustomerEntry: 0, // 是否展示智能客服入口
      smartCustomerUrl: '', // 智能客服链接
      classificaReqDTOS: [{ problemList: [] }],
      bannerUrl: '',
      otherProblems: '',
      btnConfigs: [],
    };
  },
  created() {
    const { id } = this.$route.query || {};
    if (id) {
      question
        .getTemplateDetail({ id })
        .then(res => {
          const data = res.data || {};
          this.templateName = data.templateName;
          this.smartCustomerUrl = data.smartCustomerUrl;
          this.classificaReqDTOS = data.classificaReqDTOS;
          this.telCustomerHotline = data.telCustomerHotline;
          this.showSmartCustomerEntry = data.showSmartCustomerEntry;
          this.bannerUrl = data.bannerUrl || '';
          this.otherProblems = data.otherProblems || '';
          this.btnConfigs = data.buttonCfgDTOS || [];
        })
        .catch();
    } else {
      this.$message.error('地址可能出错了');
    }
  },
  methods: {
    /** @function 获取下标对应中文name */
    indexName(idx) {
      return ['一', '二', '三', '四', '五', '六'][idx];
    },
  },
};
</script>

<style lang="scss" scoped>
.page_title {
  margin-bottom: 30px;
}
.mt20 {
  margin-top: 20px;
}
.el-form-item {
  margin-bottom: 0;
}

.tab_item {
  color: #303133;
}

.tab_item[active='true'] {
  color: #409eff;
}

.text {
  width: 100%;
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
